import React from "react";
import { graphql, PageProps } from "gatsby";

import Layout from "@/components/Layout";
import Plate from "@/components/Plate";
import Hero from "@/components/Hero";

import { Text, Box, Flex, Container } from "theme-ui";
import { m } from "framer-motion";

export interface HomeProps {
    data?: any;
}

const Departments = [
    {
        name: "Surgical Oncology",
        desc: "Surgical Oncology uses surgery to find and remove harmful tumors. This is also used in the diagnosis and finding the spread of cancer in the body.",
        path: 'surgical-oncology',
        doctors: [
            {
                name: 'Dr. Surij Salih',
                deg: 'MS MCh (Head & Neck Oncology)',
            },
        ]
    },
]

const Testimonials: React.FC<HomeProps> = ({ data }) => {
    const postEdges = (data && data.caseStudies && data.caseStudies.edges) || [];

    return (
        <Layout>
            <Plate variant="styles.contentFrame">
                <Container>
                    <Plate variant="styles.contentFrame">
                        <Flex sx={{ flexWrap: 'wrap' }}>
                            {Departments.map((dep: any) => (
                                <Box sx={{ mb: 3, maxWidth: "60ch", mr: 6, p: 5, pl: 0, pt: 0 }}>
                                    <Box sx={{ py: 3 }}>

                                        <Text as="h1" variant="pageTitle">{dep.name}</Text>

                                        <Text as="p" variant="sectionBodyAlt" sx={{ fontSize: 2 }}>
                                            {dep.desc}
                                        </Text>
                                    </Box>
                                    <Text as="h2" sx={{ fontWeight: 100, my: 3, color: 'grays.7' }}>Our Surgeons</Text>

                                    {dep.doctors.map((d: any) => (
                                        <Box sx={{ mb: 3 }}>
                                            <Text as="h2" sx={{ color: 'grays.9', fontWeight: 600 }}>{d.name}</Text>
                                            <Text as="h2" sx={{ color: 'grays.6', fontWeight: 100 }}>{d.deg}</Text>
                                        </Box>
                                    ))}
                                </Box>
                            ))}
                        </Flex>
                    </Plate>
                </Container>
            </Plate>
        </Layout>
    );
};

export default Testimonials;

